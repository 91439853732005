@import url("https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Josefin Sans", sans-serif;
}
.row_header {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
}
.Card_wrapper {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.row_header button {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: transparent;
    background-color: #3340b8;
    color: white;
    border-radius: 5px;
}
.row_header input {
    margin-left: 2rem;
}
.card {
    width: 320px;
    height: 180px;
    color: #fff;
    cursor: pointer;
    perspective: 1000px;
    position: relative;
}
.Delete {
    position: absolute;
    z-index: 20;
    right: 5px;
    top: 5px;
    border: none;
    background-color: red;
    color: white;
    padding: 0.2rem;
    border-radius: 10px;
    font-size: 0.9rem;
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.front,
.back {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 30px;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
    backface-visibility: hidden;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.card-no {
    font-size: 19px;
    margin-top: 12px;
}
.name p,
.card-no p,
.card-holder p {
    margin-bottom: 0 !important;
}
.card-holder {
    font-size: 12px;
    margin-top: 12px;
}

.name {
    font-size: 15px;
    margin-top: 2px;
}

.bar {
    background: #222;
    margin-left: -30px;
    margin-right: -30px;
    height: 60px;
    margin-top: 10px;
}

.card-cvv {
    margin-top: 20px;
}

.card-cvv div {
    flex: 1;
}

.card-cvv img {
    width: 100%;
    display: block;
    line-height: 0;
}

.card-cvv p {
    background: #fff;
    color: #000;
    font-size: 22px;
    padding: 10px 20px;
}

.card-text {
    margin-top: 30px;
    font-size: 14px;
}

.signature {
    margin-top: 30px;
}

.back {
    transform: rotateY(180deg);
}
.Card_wrapper form {
    display: grid;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 10px;
    justify-items: center;
}
.form_control {
    display: grid;
    gap: 0.7rem;
    width: 500px;
}
.Card_wrapper form h3 {
    text-align: center;
}
.form_control input {
    border: 1px solid #ccc;
    text-indent: 0.6rem;
    height: 2.5rem;
    border-radius: 10px;
    width: 100%;
}
.input {
    display: flex;
    gap: 1rem;
    width: 100%;
    /* position: relative; */
}
.input div {
    position: relative;
    width: 100%;
}
.verified {
    position: absolute;
    right: 8px;
    color: green;
    top: 10px;
}
.input button {
    border: 1px solid #ccc;
    padding: 0 1rem;
}
.Card_wrapper form > button {
    padding: 0.5rem 1rem;
    border: none;
}
.Modal_wrapper {
    background-color: white;
    padding: 0.5rem;
    border-radius: 10px;
    display: grid;
}
.Modal_wrapper h3 {
    font-weight: 500 !important;
}
.error {
    margin-bottom: 0 !important;
}
.Modal_wrapper .form_control,
.otp {
    display: grid;
    justify-items: left;
}
.Modal_wrapper input {
    width: 90%;
}
.Modal_wrapper form button {
    padding: 0.3rem 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 1rem 0;
}
.otp input {
    width: 50px !important;
    height: 50px !important;
}
.Card_success_wrapper {
    display: grid;
    justify-items: center;
}
.Card-details {
    display: grid;
    gap: 0rem;
    margin-top: 1rem;
    width: 90%;
}
.Card-details div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid black;
    align-items: center;
    /* justify-items: center; */
}
.Card-details h4 {
    background-color: lightgray;
    padding: 0.7rem 0;
    align-self: center;
    font-size: 1rem;
    margin-bottom: 0 !important;
}

.Card-details p {
    margin-bottom: 0 !important;
}
.input_search {
    display: flex;
    margin-left: 2.2rem !important;
    height: 2.5rem;
    width: 100%;
    max-width: 400px;
    border-radius: 10px !important;
    text-indent: 10px;
    border: 1px solid #ccc;
    outline: #ccc !important;
}
.input_search:focus {
    outline: none !important;
    border-color: #ccc;
}
