.Container {
    display: grid;
    gap: 2rem;
    padding: 2rem;
    font-family: "Montserrat", sans-serif;
}
.Container header {
    display: grid;
    justify-content: center;
}
.Container header h3 {
    border-bottom: 4px solid; /* Adjust the border width as needed */
    border-image: linear-gradient(
        90deg,
        #356bbd 0%,
        rgba(211, 225, 247, 0.18) 100%
    );
    border-image-slice: 1;
    padding: 10px; /* Optional: to add some padding inside the border */
    display: inline-block;
    text-align: center;
    letter-spacing: 0.05rem;
    font-size: 30px;
}
.cards {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    background: url("../../../images/HomePage_Updated/benefits.png") no-repeat
        center center;
    row-gap: 1rem;
}
.Card {
    max-width: 100%;
    width: 400px;
    display: grid;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    text-align: center;
    margin-top: 2rem;
}
.Card img {
    border-radius: 50%;
    width: 15rem;
}
.Card h3 {
    color: #0a459b;
    font-size: 30px;
}
.Card p {
    font-size: 1rem;
}
@media screen and (max-width: 900px) {
    .Card {
        width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .cards {
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 0.5rem;
    }
    .Card img {
        width: 50%;
    }
    .Card h3,
    .Container h3 {
        font-size: large;
    }
    .Card p {
        font-size: medium;
    }
}
