.Container {
    background: url("../../../images/HomePage_Updated/payBills.png") no-repeat
        center center;
    /* height: 20rem; */
    background-size: cover;
    background-position: center;
    display: grid;
    gap: 3rem;
    color: white;
    font-size: 1rem;
    padding: 2rem 10rem;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
}
.Container > h3 {
    margin: 0 !important;
    padding: 0;
    font-size: 30px;
    font-family: inherit !important;
    /* font-weight: ; */
}
.Services {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.Service_Items {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1rem;
}
.Container img {
    width: 5rem;
}
.Service_Items h3 {
    font-size: 1rem;
}

@media screen and (max-width: 1000px) {
    .Container {
        padding: 1.5rem;
        text-align: center;
    }
    .Services {
        gap: 1rem;
    }
    .Container > h3 {
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 767px) {
    .Container > h3 {
        font-size: large;
    }
    .Container img {
        width: 4rem;
    }
    .Container p {
        font-size: medium;
    }
    .Services {
        justify-content: space-around;
    }
}
