/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */
.paginationBttns {
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.paginationBttns a {
    border: 1px solid blue;
    font-size: 0.9rem;
    padding: 10px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    /* border: 1px solid#2b2eff; */
    color: #103178;
    cursor: pointer;
}

.paginationActive a {
    color: white !important;
    background-color: #103178;
}
.paginationDisabled {
    color: grey;
}

.scroll-top {
    display: none;
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    z-index: 99;
    background-color: red;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}

.heading {
    font-family: "Patua One", cursive;
    font-size: 50px;
}

h1 {
    font-family: "Patua One", cursive;
}
.refundPolicy {
    margin: 2rem 1rem 1rem 4rem;
    width: 90%;
}
.refundPolicy p,
.refundPolicy ol {
    font-size: 1.5rem;
}
/* .refundPolicy p li ol {
    font-size: 1.4rem;
    font-weight: 500;
} */
.refundPolicy #list li {
    list-style-type: circle !important ;
}
@media only screen and (max-width: 600px), (max-width: 900px) {
    .heading {
        font-size: 30px;
    }
}
