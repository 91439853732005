.Container {
    display: grid;
    align-items: center;
    font-family: "Montserrat", sans-serif;

    justify-content: center;
    padding: 2rem 0;
}
.Container h3 {
    text-align: center;
    border-bottom: 4px solid; /* Adjust the border width as needed */
    border-image: linear-gradient(
        90deg,
        #356bbd 0%,
        rgba(211, 225, 247, 0.18) 100%
    );
    border-image-slice: 1;
    padding: 10px; /* Optional: to add some padding inside the border */
    display: inline-block;
    text-align: center;
    letter-spacing: 0.05rem;
    justify-self: center;
    font-size: 30px;
}
.Container p {
    font-size: 20px;
    text-align: center;
}
.Container form {
    background-color: #f9fafd;
    padding: 2rem 4rem;
    display: grid;
    gap: 0.2rem;
    width: 500px;
}
.Container .form-control {
    display: grid;
    gap: 0.5rem;
}
.Container .form-control label {
    font-size: 16px;
    color: black;
}

.Container .form-control label span {
    color: red;
}
.Container .form-control p {
    color: red;
    font-size: 16px;
    text-align: left;
    margin: 0;
}

.Container .form-control input {
    border: 1px solid gray;
    border-radius: 20px;
    height: 2.8rem;
    text-indent: 1rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.Container form button {
    width: fit-content;
    justify-self: center;
    margin-top: 1rem;
    background-color: #356bbd;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
}

@media screen and (max-width: 767px) {
    .Container form {
        width: 350px;
    }
    .Container h3 {
        font-size: large;
    }
    .Container p {
        font-size: medium;
    }
}
