.Container {
    display: grid;
    /* gap: 11rem; */
    align-content: baseline;
    /* align-items: center; */

    background-repeat: no-repeat;
    background-size: cover;
    background: url("../../../../images/HomePage_Updated/HomePage_bg.png");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.fixednav {
    position: fixed;
    left: 0;
    right: 0;
}
.Container video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.Topnav {
    display: flex;
    /* grid-template-columns: 400px 1fr; */
    align-items: center;
    /* position: sticky; */
    top: 0;
    padding: 1rem;
    /* position: sticky; */
    top: 0;
    background: #ffffff17;
    transition: all 0.4s ease-out;
}
.img {
    flex-grow: 2;
}
.img img {
    margin-left: 4rem;
    width: 4rem;
    height: 4rem;
}
.Navbar,
.Links {
    display: flex;
    justify-content: space-around;
    /* gap: 3rem; */
    align-items: center;
    flex-grow: 2;
}
.Navbar {
    font-palette: 0.5rem;
}
.StickyNavbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.9);
}

.Navbar a {
    color: black;
    font-size: 16px;
    font-weight: 550;
    /* font-size: 0.9rem; */
    border-bottom: 3px solid transparent;
}
.active,
.Navbar a:hover {
    border-bottom: 3px solid white !important;
    color: white !important;
}
.StickyNavbar .active {
    color: #0a459b !important;
    border-bottom: 3px solid #0a459b !important;
}
.Signup {
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    padding: 0.5rem 0.7rem;
    gap: 0.5rem;
    background-color: #356bbd;
    color: white;
    color: white !important;
    border-radius: 30px;
}
.Signup span {
    color: #0a459b !important;
    background-color: white;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Navbar-Content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* padding: 4rem 0rem; */
    padding-top: 2rem;
    padding-bottom: 6rem;
}
.Navbar-Content_left {
    display: grid;
    gap: 1rem;
    margin-top: 6rem;
    margin-left: 4rem;
}
.Navbar-Content {
    display: grid;
    gap: 2rem;
    /* padding: 0 2rem; */
    /* grid-template-columns: 1fr 1fr; */
    align-items: center;
    justify-items: center;
}
.Action-controls {
    display: flex;
    gap: 1rem;
}

.Navbar-Content_left h3 {
    font-size: 45px;
    background-clip: text;
    background-image: linear-gradient(to right, #0c2d58, #308aff);
    -webkit-text-fill-color: transparent;
    font-weight: 600 !important;
    border-bottom: 4px solid;
    border-image: linear-gradient(
        90deg,
        #356bbd 0%,
        rgba(211, 225, 247, 0.18) 100%
    );
    width: 65%;
    border-image-slice: 1;
}
.Navbar-Content_left p {
    width: 100%;
    max-width: 550px;
    font-size: 20px;
}
.Navbar-Content_left button {
    width: fit-content;
    background-color: #356bbd;
    border: none;
    color: white;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 20px;
}
.Navbar-Content_right {
    display: flex;
    justify-content: flex-end;
}
.Navbar-Content_right img {
    /* width: 100%; */
    height: 30rem;
    justify-self: right;
}

.hamburger-btn {
    display: none;
    border: 1px solid #ccc;
    padding: 0 0.5rem;
    font-size: 1.5rem;
}
.StickyNavbar {
    padding: 0.8rem;
}
.StickyNavbar .img img {
    width: 3rem;
    height: 3rem;
}
.StickyNavbar a:hover {
    color: #0a459b !important;
    border-color: #0a459b !important;
}
@media screen and (max-width: 1200px) {
    .Navbar-Content_right {
        justify-self: right;
    }
}
@media screen and (max-width: 1000px) {
    .Navbar-Content_right img {
        height: 100%;
    }
    .Navbar button {
        display: block;
    }
    .Signup span {
        display: none;
    }
    .Topnav {
        display: flex;
        justify-content: space-between;
    }
    .Links {
        display: none;
    }
    .Links_show {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        top: 100px;
        padding: 1rem;
        background-color: #f6f9fc;
        left: 0;
        right: 0;
    }
    .Navbar {
        justify-content: right;
    }
    .Navbar-Content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .Navbar-Content_left h3 {
        font-size: 30px;
    }
    .Navbar-Content_left p {
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {
    .Navbar-Content {
        display: flex;
        flex-direction: column-reverse;
        gap: 2.5rem;
        padding: 3rem 2rem;
    }
    .Navbar-Content button {
        font-size: medium;
        padding: 1rem;
    }
    .Navbar-Content_left {
        margin: 0;
    }
    .img img {
        margin-left: 0;
    }
}
@media screen and (max-width: 567px) {
    .Navbar-Content_left h3 {
        font-size: large;
    }
    .Navbar-Content_left p {
        font-size: medium;
    }
    .Navbar-Content_right img {
        width: 90%;
        justify-self: center;
        margin-left: 0;
    }
}
