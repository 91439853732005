.Container {
    background-color: #505050;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: center;
    justify-items: center;
    row-gap: 2rem;
    padding: 2rem;
    font-family: "Montserrat", sans-serif;
}
.Link1 {
    display: grid;
    gap: 1rem;
    align-self: baseline;
}
.Link1 div {
    background-color: white;
    padding: 1rem;
    width: fit-content;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Link1 img {
    /* margin: auto; */
    width: 90%;
}
.Link1 p {
    font-size: 18px;
    color: white;
    width: 90%;
}
.icons {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 1rem;
}
.icons a {
    display: flex;

    align-items: center;
    justify-content: center;
    color: black;
    background-color: #d3d3d3;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}
.Link2 {
    display: grid;
    gap: 1rem;
}
.Link2 h3 {
    color: #4b79d3;
    font-size: 18px;
    /* padding-bottom: 1rem; */
    align-self: bottom;
}
.Link2 a {
    font-size: 16px;
    color: white;
}
.Link3 {
    display: grid;
    gap: 1rem;
    align-self: baseline;
    color: white;
    text-align: center;
}
.Link3 .Signup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: #356bbd;
    color: white;
    color: white !important;
    border-radius: 30px;
}

.Signup span {
    color: #0a459b !important;
    background-color: white;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Link3 p {
    font-size: 18px;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .Container {
        justify-items: left;
    }
}
