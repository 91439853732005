.wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2rem 0;
    /* width: 300px; */
}
.Container {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    width: 70%;
    align-items: center;
    align-content: center;
    height: fit-content;

    font-family: "Montserrat", sans-serif;
}
.border {
    position: relative;
    height: 3rem;
}
.close {
    position: absolute;
    right: 10px;
    top: 2px;
    border: none;
    font-size: 25px;
    background-color: inherit;
    outline: none;
}
.Container h3,
.border h3 {
    text-align: center;
    font-size: 25px;
}
.Container p {
    text-align: center;
}
.Container input {
    border: 1px solid #bababa !important;
    outline: none;
    box-shadow: none !important;
    border-radius: 10px !important;
    text-indent: 3rem;
}
.Container > button {
    background-color: #356bbd;
    color: white;
    border: none;
    height: 3rem;
    border-radius: 10px;
}
.resend {
    align-self: right;
    justify-self: right;
    width: fit-content;
}
.resend button {
    text-decoration: underline;
    border: none;
    color: black;
}
.resend button:disabled {
    color: gray !important;
}
.border {
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
}
.singup_button {
    margin-top: 2rem;
}
.input-wrapper {
    width: 100%;
    position: relative;
}
.icon {
    top: 15px;
    color: #356bbd;
    font-size: 18px;
    left: 15px;
    position: absolute;
}
.action-btns {
    display: grid;
    /* justify-content: center; */
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}
.action-btns button:first-child {
    background-color: #356bbd;
    color: white;
    border: none;
    width: 100%;
    height: 2.5rem;
    border-radius: 10px;
    text-align: center;
}
.action-btns button:nth-child(2) {
    justify-self: right;
    border: none;
    text-decoration: underline;
    /* text-transform: lowercase; */
}
.terms {
    font-size: 10px;
    color: #52525d;
    font-weight: 400;
    display: flex;
}
.terms input {
    border: 1px solid #ccc;
    text-indent: 0;
    position: initial;
    opacity: 1;
}
