.wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2rem 0;
    /* width: 300px; */
}
.Container {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    width: 70%;
    align-items: center;
    align-content: center;
    height: fit-content;

    font-family: "Montserrat", sans-serif;
}
.border {
    position: relative;
    border-bottom: 1px solid #ccc;
    height: 3rem;
}
.close {
    position: absolute;
    right: 10px;
    top: 0px;
    border: none;
    font-size: 20px;
    background-color: inherit;
    outline: none;
}
.Container h3,
.border h3 {
    text-align: center;
    font-size: 25px;
    margin: 0;
}
.Container p {
    text-align: center;
    margin: 0;
}
.Container > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
