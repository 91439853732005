.signup_left {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: url(./assets/bg.png) bottom left no-repeat; */
    background-color: #6865f0;
    background-size: 100%;
    justify-content: center;
    min-height: 400px;
    padding: 40px 10px 20px 10px;
    overflow: hidden;
    position: relative;
}

.signup_left::after {
    content: "";
    position: absolute;
    background-color: rgb(255 249 249 / 9%);
    background-size: 100%;
    height: 100px;
    width: 100px;
    left: -30px;
    border-radius: 50%;
    bottom: -30px;
}

.signup_left::before {
    content: "";
    position: absolute;
    background-color: rgb(255 249 249 / 9%);
    background-size: 100%;
    height: 40px;
    width: 40px;
    transform: rotate(50deg);
    top: 50px;
    right: 100px;
}
.modal {
    min-height: 100vh !important;
    height: 100%;
}

.signup_container {
    height: 350px;
    margin: 0px;
    padding: 0px;
}

.signup_title {
    font-size: 25px;
    font-weight: normal;
    margin-bottom: 10px;
}

.signup_left p {
    font-size: 11px;
    text-align: center;
    letter-spacing: 1px;
    margin: 5px;
}

.loginButton {
    width: 120px;
    height: 35px;
    border-radius: 20px;
    background: transparent;
    color: white;
    border: 2px solid white;
    margin: 20px;
    padding: 0px 0px;
}

.modal-body {
    padding: 0px !important;
}

.modal-content {
    border: none !important;
    padding: 0 !important;
    width: 550px;
    margin: auto;
    height: fit-content;
}

.modal-lg,
.modal-xl {
    width: 80% !important;
}

.signup_right {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 10px 20px 10px;
}

.signup_right h1 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #6865f0;
}

.wrapper_buttons {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.wrapper_buttons button {
    color: #c4c4c4;
    border: 0px solid #6865f0;
    padding: 5px 2px;
    margin: 10px;
    width: 100px;
    height: 35px;
    border-radius: 10px;
    background: transparent;
    font-size: 13px;
}

.active_button {
    border: 1px solid #6865f0 !important;
    color: #6865f0 !important;
    font-weight: 500 !important;
}

.business {
    color: #c4c4c4;
    border: 0px solid #6865f0;
    padding: 5px 2px;
    margin: 10px;
    width: 100px;
    height: 35px;
    border-radius: 20px;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
}

.inputWrapper {
    padding: 5px 10px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: 12px;
}

.inputWrapper input {
    border: none;
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
    color: #52525d;
    font-weight: 500;
    font-family: Poppins;
}

.inputWrapper input:focus,
.form-control:focus {
    border-color: none !important;
    -webkit-box-shadow: none;
    box-shadow: none !important;
    /* background: transparent; */
}
.inputWrapper input:-webkit-autofill,
.inputWrapper input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
.inputWrapper input[data-autocompleted] {
    background-color: transparent !important;
}
.inputWrapper img {
    width: 18px;
    object-fit: cover;
    margin: 2px 10px;
}

.getOTP {
    width: 86%;
    height: 35px;
    border-radius: 20px;
    background-color: #f40000;
    color: white;
    border: 0px solid white;
    margin: 20px;
    padding: 0px 0px;
    font-weight: 700;
    font-family: "Poppins";
}

.referral,
.forgetpassword {
    font-size: 16px;
    color: #f40000;
    font-weight: 500;
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    font-family: "Poppins";
    margin-left: 30px;
    margin-top: 25px;
}

.terms {
    font-size: 10px;
    color: #52525d;
    font-weight: 400;
    margin: 20px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: top;
}

.text-danger {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
}

:disabled {
    opacity: 0.5;
}

.login-modal1 {
    /* width: 95% !important; */
    margin: auto;
    max-width: 600px !important;
}

.bg-pic-div {
    height: 400px;
    background-size: cover !important;
    padding: 20px;
    width: 100%;
}

.form-div-1-sub {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 1rem;
}

.signup-head {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #262626;
    margin: 0;
    letter-spacing: 0.04em;
}
.welcome {
    font-size: 20px;
}
.resend {
    display: grid;
    width: 100%;
    justify-items: right;
    width: 90%;
}
.resend button {
    border: none;
    background-color: inherit;
}
.close-x {
    background: rgba(255, 255, 255, 0.72);
    border: 1px solid #f40000;
    margin: 15px;
    font-size: 25px;
    /* padding: 5px 15px; */
    padding: 0 1rem;
    display: grid;
    align-items: center;
    justify-items: center;
}
