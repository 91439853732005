.left-side {
    background-color: #010080;
}

/* a {
    color: #FFFFFF !important;
} */

.main-row {
    height: 100vh;
    width: 100%;
    margin: 0;
}

.logo {
    /* background: white; */
    width: fit-content;
    padding: 4px;
    margin: auto;
    margin-top: 30px;
}

.logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Poppins", sans-serif;
    margin-top: 3px;
}

.head-hi {
    color: #52525d;
    margin: 0;
    font-family: "Rubik";
    font-size: 19px;
    margin-left: 5px;
    font-weight: 400;
}
.marquee {
    width: 350px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}
@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}
.user-avatar-div {
    display: flex;
    justify-content: center;
}

.user-avatar-div img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    object-fit: contain;
}

.user-name {
    margin: 0;
    text-align: center;
    font-family: "Poppins";
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.user-city {
    text-align: center;
    font-family: "Poppins";
    color: #ffffff;
    margin: 0;
}
.search-payout {
    position: relative;
    display: flex;
    align-items: center;
    width: 70%;
    margin-left: 30px;
    /* width: fit-content; */
    /* justify-content: center; */
}
.search-payout .search {
    background: #00000000;
    /* border-radius: 20px; */
    border: 1px solid #010080;
    color: #010080;
    font-family: "Poppins";
    height: 3.35rem;
    font-size: 16px;
}
.search-payout div {
    position: relative;
}
.close {
    position: absolute;
    left: -35px;
    top: 13px;
    background-color: white;
    color: white;
    border: none;
    font-size: 13px;
}
/* .close button:cli */
.search-payout input {
    text-indent: 1rem;
    width: 80%;
    height: 3.2rem;
    margin: 0;
    border: 1px solid black;
}
.pay-bills-flex {
    display: flex;
    justify-content: space-between;
    margin: 10px 30px;
}
.nav-list {
    list-style-type: none;
    padding: 0px;
    max-width: 220px;
    margin: auto;
    font-family: "Poppins";
    font-weight: 400;
    text-align: center;
}

.nav-list li {
    /* background: #FFFFFF; */
    border-radius: 14px;
    color: #ffffff;
    padding: 5px 20px;
    font-size: 16px;
    text-align: left;
}

.nav-list li a {
    text-decoration: none;
}

.selected-menuitem {
    background-color: #ffffff !important;
    color: #010080 !important;
}

.selected-menuitem a {
    color: #010080 !important;
}

.head-dashboard {
    color: #5b5b5a;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 30px;
}

.head-hi {
    color: #52525d;
    margin: 0;
    font-family: "Poppins";
    font-size: 19px;
    margin-left: 5px;
    font-weight: 400;
}

.blc-section {
    background: #ffff;
    border-radius: 4px;
    padding: 20px;
    margin: 10px 0px;
}

.your-blc {
    color: #a0a2ae;
    font-family: "Poppins";
    font-size: 20px;
    letter-spacing: 0.8px;
    margin: 0;
}

.blc-rs {
    color: #252523;
    font-size: 40px;
    font-weight: 500;
    font-family: "Poppins";
    margin: 0;
}

/* Right back */
.right-back {
    background: #e5e5e55e;
    padding: 0;
}

/* Header Row */
.header-row {
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.header-sub-row {
    display: flex;
    align-items: center;
}

.header-icon {
    height: 20px;
    width: 20px;
    margin: 6px;
}

.header-blc {
    margin: 0;
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 500;
    color: #252523;
}

/* Deposit section */
.deposit-head {
    font-size: 30px;
    font-family: "Poppins";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 14px;
    max-width: 300px;
}

.total-wallet-box-main {
    max-width: 500px;
    margin: 30px auto;
}

.total-wallet-box-sub {
    background: #00bfd2;
    box-shadow: 0px 4px 100px rgb(206 95 216 / 40%);
    border-radius: 18px;
    padding: 24px 0px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.total-blc-p {
    color: #ffffff;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 500;
}

.total-blc-rs {
    color: #ffffff;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 1px;
}

.add-blc-ip {
    width: 100%;
    border: 0;
    border-radius: 12px;
    height: 42px;
    padding: 20px;
    text-indent: 30px;
    font-size: 18px;
    font-weight: 500;
    color: #52525d;
    outline: none;
    box-shadow: 0px 4px 20px 2px rgb(53 0 109 / 7%);
}

.add-blc-notes {
    width: 100%;
    border: 1px solid #010080;
    border-radius: 20px;
    text-indent: 49px;
    font-size: 24px;
    font-weight: 400;
    color: #010080;
    letter-spacing: 0.8px;
    margin-top: 10px;
    outline: none;
}

.ip-inr-main {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ip-inr-main-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ip-inr {
    color: #f40000;
    font-family: "Rubik";
    font-size: 22px;
    font-weight: 400;
    position: relative;
    left: 20px;
    top: 45px;
}

.add-btn-div {
    display: flex;
    margin-top: 45px;
}

.add-btn-div1 {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
}

.add-btn {
    background: #010080;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    width: 150px;
    font-family: "Poppins";
    font-size: 20px;
    margin: auto;
    height: 42px;
    margin-left: 10px;
}

/* Pay bills */
.pay-bills-grp1 {
    display: flex;
    justify-content: space-around;
}

.pay-bills-head {
    font-size: 40px;
    font-family: "Poppins";
    font-weight: 600;
    color: #5b5b5a;
    margin-top: 25px;
    width: 500px;
}

.see-billers-btn {
    background: #00000000;
    border-radius: 20px;
    border: 1px solid #010080;

    color: #010080;
    font-family: "Poppins";
    font-size: 16px;
    margin: auto;
    height: 41px;
    width: 208px;
}

.pay-bills-center {
    max-width: 400px;
    margin: 30px auto;
}

.pay-bills-main-img {
    width: -webkit-fill-available;
}
.acceptPayoutcharge-wrapper {
    gap: 1rem;
}
.acceptPayoutcharge,
.acceptPayoutcharge-wrapper {
    display: flex;
    align-items: center;
    /* width: ; */
}
.acceptPayoutcharge label {
    font-size: 1rem;
    /* margin: 0 !important;
    padding: 0 !important; */
    margin: 0 0 0rem 1.5rem;
    color: red;
}
.acceptPayoutcharge input {
    /* padding-bottom: 2rem !important; */
    margin-bottom: 0.8rem;
}
.add-your-p {
    color: #52525d;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 2px;
}

.add-more-p {
    color: #52525d;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    letter-spacing: 0.2px;
}

.add-biller-btn {
    background: #010080;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    width: 80%;
    font-family: "Poppins";
    font-size: 20px;
    margin: auto;
    height: 50px;
}

.form-main {
    width: 74%;
    margin: 50px auto 0px auto;
    padding: 32px;
    background: #ffffff;
}

.form-sub {
    max-width: 456px;
    margin: auto;
}

.pay-bill-ip {
    width: 100%;
    border: 1px solid #010080;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #010080;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.grid1-main {
    width: 84%;
    margin: 10px auto 0px auto;
    padding: 32px;
    /* background: #FFFFFF; */
}

.grid1-main-w {
    width: 50%;
    margin: 10px auto 0px auto;
    padding: 32px;
}

.payee-col {
    text-align: center;
    margin-top: 10px;
}
.delete-payout {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8rem;
    background-color: red;
    border: none;
    color: white;
    border-top-right-radius: 5px;
    cursor: pointer;
    visibility: hidden;
}
.payee-col:hover .delete-payout {
    visibility: visible;
}
.payee-col-sub {
    background: #ffffff;
    border: 0;
    padding: 0px 0px;
    border-radius: 10px;
    text-transform: capitalize;
    color: #30303c;
    height: 100%;
}
.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.payee-col-sub:hover {
    background: #010080;
    border: 0;
    /* padding: 20px 0px; */
    border-radius: 10px;
    text-transform: capitalize;
    color: #ffffff;
}

.first-letter {
    background: #ffc107;
    color: white;
    width: 68px;
    margin: auto;
    padding: 10px;
    border-radius: 50px;
}
.ac-name-wrapper {
    width: 90%;
    margin: 1rem 0;
    display: grid;
    gap: 0.5rem;
}
.ac-name {
    font-weight: 500;
    letter-spacing: 0.02em;
    /* color: #FFFFFF; */
    font-size: 13px;
    /* max-width: 100px; */
    word-wrap: break-word;
    text-align: center;
    /* word-wrap: break-word; */
    text-transform: capitalize;
    font-family: "Poppins";
    /* margin-top: 10px; */
    /* padding: 0 0.5rem; */
    margin-bottom: 0;
}

.ac-number {
    margin-bottom: 0;
    /* color: #FFFFFF; */
    font-size: 13px;
    font-family: "Poppins";
}

.error-text {
    color: red;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}

.error-text1 {
    color: red;
    text-align: center;
    width: 100%;
    margin-top: 0;
}

.c-modal-pay-bills {
    max-width: 480px !important;
    text-align: center;
}

.pay-ip-div {
    max-width: 380px;
    margin: auto;
}

.m-name {
    padding: 20px;
}

.m-name-payee {
    color: #252523;
    font-family: "Poppins";
    font-size: 26px;
    letter-spacing: 0.4px;
    margin: 0;
}

.m-mobile-payee {
    color: #52525d;
    font-family: "Poppins";
    font-size: 16px;
    letter-spacing: 0.4px;
}
.pay-ip-div input::-webkit-outer-spin-button,
.pay-ip-div input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pay-ip-div input[type="number"] {
    -moz-appearance: textfield;
}
.pay-ip-div-e1 {
    width: 100%;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    border: none;
    background: rgba(82, 82, 93, 0.07);
}

.pay-ip-div-e2 {
    width: 100%;
    border-radius: 20px;
    height: 120px;
    padding: 20px;
    text-indent: 10px;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    border: none;
    background: rgba(82, 82, 93, 0.07);
}

.arrow-btn-payee {
    background: #010080;
    padding: 10px 8px;
    border-radius: 40px;
    height: 40px;
    margin: 40px;
    cursor: pointer;
}

/* Transaction */
.total-transaction-box-main {
    width: 90%;
    margin: 24px auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
}

/* .total-transaction-box-main table {
    border-collapse: inherit;
    border-spacing: 0 10px;
} */

.transaction-head {
    font-size: 32px;
    font-family: "Poppins";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 20px;
    /* max-width: 260px; */
}

.date-export-divs {
    /* width: 86%; */
    margin: auto;
}

.date-ips {
    margin: 4px;
    background: #ffffff;
    border: 1px solid #010080;
    border-radius: 10px;
    color: #f40000;
    padding: 5px 2px;
    font-size: 14px;
    font-family: "Poppins";
    text-indent: 4px;
    width: 156px;
}

.export-div {
    background: #010080;
    cursor: pointer;
    font-family: "Poppins";
    margin: auto 8px;
    height: fit-content;
    padding: 5px 8px;
    border-radius: 18px;
}

.trans-row th {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
}

.trans-row1 {
    color: #52525d;
    font-size: 15px;
    font-family: "Poppins";
    cursor: pointer;
}

.trans-row1 td {
    text-align: center;
}

.completed-span {
    /* background: #1fb770; */
    /* color: white; */
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="rgb(244, 0, 0)" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.go-btn {
    background: #010080;
    color: white;
    padding: 3px 5px;
    font-size: 18px;
    font-family: "Poppins";
    border-radius: 18px;
    height: max-content;
    margin: auto;
    margin-left: 10px;
}

/* Account Profile */
.account-head {
    font-size: 32px;
    font-family: "Poppins";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin: auto;
}

.account-box-main {
    width: 90%;
    margin: 24px auto;
    padding: 5px;
    /* background: #FFFFFF; */
    border-radius: 20px;
    text-align: center;
}

.account-box-main1 {
    background: #ffffff;
    padding: 10px 24px;
    border-radius: 24px 24px 0px 0px;
}

.profile-changep-div {
    display: flex;
    align-items: center;
    width: 100%;
}
.profile-container {
    display: grid;
    justify-items: center;
}
.profile-details-wrapper {
    display: grid;
    justify-items: center;
}
.profile-detailss {
    width: 500px;
    display: grid;
    font-size: 1.3rem;
    grid-template-columns: 30% 5% 55%;
    gap: 1rem;
}
.profile-detailss strong {
    justify-self: right;
}
.profile-detailss p {
    text-align: left;
    margin: 0;
    justify-self: left;
}
.profile-password-selected {
    border: 0;
    background: none;
    color: #010080;
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 500;
    letter-spacing: 0.4px;
    border-bottom: 4px solid #010080;
    padding: 0 8px;
}

.profile-password-not-selected {
    border: 0;
    background: none;
    color: #52525d;
    font-size: 20px;
    font-family: "Poppins";
    letter-spacing: 0.4px;
    padding: 0 8px;
}

.account-box-main2 {
    background: #ffffff;
    padding: 10px 24px;
    margin-top: 6px;
}

.user-name-account {
    margin: 0;
    text-align: center;
    font-family: "Poppins";
    color: #252523;
    font-size: 20px;
    font-weight: 500;
}

.user-ref-account {
    margin: 0;
    text-align: center;
    font-family: "Poppins";
    color: #252523;
    font-size: 17px;
    font-weight: 400;
}

.user-details-ips {
    max-width: 420px;
    margin: auto;
    text-align: start;
}

.ip-email-p {
    color: #010080;
    font-family: "Poppins";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 32px;
}

.email-ip-p {
    width: 100%;
    border: 1px solid #010080;
    border-radius: 20px;
    height: 40px;
    text-indent: 70px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
}

.ip-mobile-p {
    color: #010080;
    font-family: "Poppins";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 34px;
}

.account-box-main3 {
    background: #ffffff;
    padding: 10px 24px;
    margin-top: 6px;
    padding-top: 68px;
}

.ip-password-img {
    color: #010080;
    font-family: "Poppins";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 40px;
}

.password-ip-p {
    width: 100%;
    border: 1px solid #010080;
    border-radius: 20px;
    height: 50px;
    text-indent: 70px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
}

/* Wallet Transfers */
.search-payers {
    border: 1px solid #010080;
    max-width: 440px;
    width: 100%;
    border-radius: 15px;
    color: #52525d;
    font-family: "Poppins";
    font-size: 21px;
    text-indent: 17px;
}

.mar-top-50 {
    margin-top: 50px;
}

.pay-w-bills-center {
    max-width: 400px;
    margin: 0vh auto;
}

.search-user-btn {
    padding: 2px 10px;
    border: none;
    border-radius: 20px;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    background: #010080;
    letter-spacing: 0.2px;
    /* margin: 8px 0px; */
    outline: none;
    color: white;
    margin: auto;
}

.add-user-dropdown {
    border: 0;
    border-radius: 20px;
    height: 50px !important;
    text-indent: 10px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    padding-left: 18px;
}

.t-details-div {
    padding: 15px;
    border: 3px solid rgba(0, 0, 0, 0.17);
    margin: 15px;
    font-family: "Poppins";
    text-align: left;
    font-weight: 400;
}

.pay-bill-ip1 {
    width: 100%;
    border: 0;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #010080;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.titel {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #5b5b5a;
}

.label {
    font-size: 20px;
    font-weight: 600;
    padding: 8px;
}

.resend-btn {
    padding: 4px;
    display: flex;
    margin: auto;
    background: #010080;
    color: #ffffff;
}

.hero-header {
    background: #f6f7ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pay-mark-logo {
    height: 80px;
    padding: 2px 18px;
}

.hero-head-rs {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #010080;
    margin: auto 2px;
}

.hero-head-wallet {
    height: 30px;
    margin: auto 2px;
    cursor: pointer;
}

.hero-head-icons {
    height: 30px;
    margin: auto 10px;
    cursor: pointer;
}

.nav-header {
    background: #010080;
    padding: 18px 10px;
    display: flex;
    justify-content: center;
}

.icon-name-div {
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-size: 14px;
    margin: 0 8px;
}
.icon-name-div a {
    color: white !important;
}

.menu-icon {
    width: 15px;
    height: 15px;
    margin: 0px 2px;
}

.op1 {
    opacity: 1;
}

.op5 {
    opacity: 0.7;
}

.a-center {
    align-items: center;
    width: 100%;
}

.wallet-icon {
    width: 30px;
    margin: 10px;
}

.main-bg {
    min-height: 100vh;
    height: 100%;
    background: #f6f7ff;
}

.main-bg1 {
    background: #f6f7ff;
    min-height: 100vh;
}

.add-img-w {
    width: 70%;
    margin: auto;
    background: #ffffff;
    padding: 5px;
}

.card-avatar {
    width: 50px;
}

.card-img-name {
    padding: 0 0.7rem;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.kyc-main-div {
    width: 60%;
    padding: 92px 50px;
    background: #fbfcff;
    border: 4px solid #ffffff;
    border-radius: 20px;
}

.kyc-head {
    font-size: 32px;
    font-family: "Poppins";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 20px;
    max-width: 350px;
}

.upload-docs-label {
    font-size: 18px;
    font-family: "Poppins";
    padding-left: 15px;
}

.upload-img-png {
    margin: auto;
    width: 112px;
    image-rendering: -webkit-optimize-contrast;
    padding: 5px;
    cursor: pointer;
}

.mar-t-b-5 {
    margin-top: 8px;
}

.d-flex-j-sp {
    display: flex;
    justify-content: space-between;
}

.pay-url {
    font-size: 12px;
    color: #010080;
}

@media screen and (max-width: 1110px) {
    .pay-bills-head {
        font-size: 28px;
    }
}
@media screen and (max-width: 680px) {
    .kyc-main-div {
        width: 92% !important;
        padding: 20px 15px !important;
        background: #fbfcff !important;
        border: 4px solid #ffffff !important;
        border-radius: 20px !important;
    }

    .ip-inr-main {
        display: block !important;
        width: 100% !important;
        justify-content: space-between !important;
    }
}

.dates-ips {
    display: flex;
}

.terms-main {
    width: 90%;
    margin: auto;
    font-family: "Poppins";
    font-size: 15px;
    text-align: justify;
}

.terms-sub {
    font-weight: 500;
    font-size: 22px !important;
    margin-bottom: 5px;
    margin-top: 15px;
}

.service-box-23-row {
    padding: 12px;
}

.service-box-23 {
    padding: 10px 5px;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
}

.service-box-icon {
    height: 50px;
    width: 50px;
    object-fit: cover;
    margin-right: 8px;
}

/* bill payment */
.payment-wrapper {
    margin: 3rem 4rem;
    display: grid;
    gap: 2rem;
}
.paymentType-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 1rem;
    margin: 0 5rem;
    row-gap: 2rem;
}
.Insurance-Wrapper {
    display: flex;
    margin: 0 5rem;
    gap: 2rem;
}

.payment-header {
    display: flex;
    justify-content: space-between;
}
.paymentWrapper {
    display: grid;
    gap: 1rem;
    margin: 2rem 5rem;
    margin-bottom: 0rem;
    margin-bottom: 0rem;
}
.header-wrapper {
    display: flex;
}
.header-wrapper h3 {
    align-self: flex-end;
    margin-bottom: 0.7rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;

    text-align: center;
    letter-spacing: 0.02em;

    color: #f40000;
}
.header-wrapper h2 {
    align-self: flex-start;
}
.payment-header h2,
.payment-header .header-wrapper h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    /* line-height: 40px; */
    letter-spacing: 0.01em;

    color: #30303c;
}
.payment-header-icon {
    height: 45px;
}
.payment-type {
    display: grid;
    gap: 1rem;
    width: 170px;
    justify-items: center;

    background: #ffffff;
    padding: 1rem;
    border: none;
}
.paymentType_icon {
    /* width: 10%; */
    width: 50px;
    height: 50px;
    align-self: center;
}

.payment-type h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    /* line-height: 22px; */
    /* width: 60%; */
    /* or 110% */

    text-align: center;
    letter-spacing: 0.02em;
    color: #252523;
}
.paymentNav {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    margin: 0 5rem;
}

.billNavactive {
    color: #010080;
    padding: 0 1.5rem 1rem 1.5rem;

    border-bottom: 10px solid #010080;
}
.billNavinactive {
    color: #6d6d6b;
    padding: 0 1.5rem 1rem 1.5rem;
    /* padding-top: 0; */
    border: none;
}
.Complaint-Nav {
    margin: 3rem;
}
.Complaint-wrapper {
    display: grid;
    gap: 0.7rem;
    margin-left: 1rem;
    background: #f6f7ff;
}
.Complaint-submenuWrapper {
    display: grid;
    grid-template-columns: 20% 78%;
    gap: 1rem;
    height: 100%;
}
.Complaint-submenu {
    /* justify-content: center; */
    min-height: 50vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    /* padding: 1rem; */
    padding-top: 1rem;
    text-align: center;
}
.Complaint-submenu a:hover {
    color: red;
}
.activesubmenu {
    color: red;
    border-bottom: 1px solid red;
    padding: 0 1.5rem 1rem 1.5rem;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
}
.inactivesubmenu {
    font-weight: normal;
    color: #6d6d6b;
    border-bottom: 1px solid #6d6d6b;
    padding: 0 1.5rem 1rem 1.5rem;
    font-family: "Poppins";
    font-style: normal;

    font-size: 1rem;
    /* line-height: 22px; */
}
.Complaint-formwrapper {
    display: grid;
    gap: 2rem;
    background-color: white;
    padding: 2rem;
    /* padding-bottom: 10rem; */
}
.Complaint-formwrapper h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    /* line-height: 22px; */
    /* identical to box height, or 50% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.Complaint-formwrapper form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}
.Complaint-formwrapper form .button {
    align-self: end;
    justify-self: end;
    grid-column: 1/-1;
    padding-top: 2rem;
}
.Complaint-formwrapper form .button button {
    background: #010080;
    color: white;
    border: none;
    padding: 1.5rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}
.Complaint-formwrapper form .button button:active {
    background-color: white;
    color: #010080;
    border: 1px solid #010080;
}
.Complaint-form-control {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: center; */
}
.Complaint-form-control label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-formwrapper form .Complaint-form-control .input {
    border: 1px solid #52525d;
    width: 90%;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;
}
.Complaint-formwrapper form .Complaint-form-control .textarea {
    border: 1px solid #52525d;
    width: 90%;

    border-radius: 7px;
    text-indent: 1rem;
    height: 8rem;
}
.Complaint-formwrapper form .Complaint-form-control .input:focus {
    border: 1px solid black;
    box-shadow: none;
    outline: none;
}
.Complaint-form-control input::-webkit-outer-spin-button,
.Complaint-form-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.Complaint-form-control input[type="number"] {
    -moz-appearance: textfield;
}
.Complaint-form-control .textarea {
    resize: none;
    /* height: 5rem; */
    border-radius: 7px;
}
.TypeOfComplaint-wrapper {
    display: grid;
    gap: 1rem;
}
.TypeOfComplaint-wrapper label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    color: #52525d;
}
.complaint-type {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.complaint-type p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* or 92% */

    letter-spacing: 0.02em;
    margin: 0;
    color: #52525d;
}
.complaint-type input {
    opacity: 1 !important;
    position: unset !important;
    pointer-events: initial !important;
}
.Complaint-form-control .select option {
    max-width: 500px;
    height: 5rem;
}
.Complaint-form-control .select,
.Complaint-form-control .select option {
    display: block;
    border: 1px solid #52525d;
    width: 90% !important;
    overflow: hidden;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    /* text-indent: 3.5rem; */
}
/* ---------- Billpayment-Transaction */

.TranscationWrapper {
    background-color: #f6f7ff;
    display: grid;
    gap: 2rem;
    padding: 2rem;
}
.transcations {
    display: grid;
    gap: 2rem;
    background-color: white;
    padding: 3rem;
    margin: 0 1.5rem;
}
.filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    gap: 2rem;
}
.filter-control {
    display: grid;
    gap: 1rem;
    align-items: center;
}
.filter-control input[type="date"]::-webkit-inner-spin-button,
.filter-control input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.filter-control input::-webkit-outer-spin-button,
.filter-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.filter-control input[type="number"] {
    -moz-appearance: textfield;
}
.filter .filter-control .input {
    margin: 0;
    border: 1px solid grey;
    text-indent: 0.5rem;
    box-shadow: none;
    width: 100%;
    border-radius: 10px;
}
input:focus {
    border: 1px solid black;
    box-shadow: none;
    outline: none;
}
.filter-control label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}

.transcations table tr {
    border-bottom: 1px solid #e4e4e4;
    background: #e4e4e4;
}
.transcations table tr {
    padding: 0.5rem 1rem;
}
.transcations table tr th {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.01em;

    color: #252523;
}
.transcations table tr td {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.02em;

    color: #52525d;
}
.transcationTable {
    overflow-x: auto;
}
.transcationTable table tr td button {
    border: none;
    background-color: white;
}
.filter button {
    color: white;
    background-color: #010080;
    border: none;
    padding: 0.5rem 4rem;
    border-radius: 10px;
    font-size: 1.4rem;
    width: fit-content;
    height: fit-content;
    align-self: end;
}
@media screen and (max-width: 767px) {
    .payment-wrapper {
        margin: 2rem 0.6rem;
    }
    .paymentType-wrapper {
        justify-items: center;
    }
    .payment-header {
        display: flex;
        justify-content: center;
    }
    .payment-header h2,
    .payment-header .header-wrapper {
        display: none;
    }
    .paymentNav {
        margin: 0;
        font-size: 1rem;
        align-self: left;
    }
    .activesubmenu,
    .inactivesubmenu {
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 1390px) {
    .Complaint-formwrapper form {
        display: flex;

        flex-direction: column;
        gap: 1rem;
        margin-left: 10rem;
    }

    .Complaint-form-control input,
    .Complaint-form-control .textarea {
        width: 100%;
        max-width: 70rem;
    }
}
/* -----paybill--------------- */
.biller-details-wrapper {
    display: grid;
}
.biller-details {
    display: grid;
    gap: 2rem;
    background-color: white;
    padding: 3rem 0 2rem 0;
    border-radius: 10px;
    margin: 5rem;
    max-width: 50rem;
    margin-left: 17rem;
}
.biller-detail {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.biller-details .icon {
    position: absolute;
    top: 20%;
    left: 13%;
    color: red;
}
.biller-details .biller-detail input {
    border: none;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 3.5rem;
    text-indent: 2rem;
    border-radius: 7px;
    text-indent: 4.5rem;
    font-size: 1.2rem;
}
.biller-details .biller-detail #billerinput:focus {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.biller-details .biller-detail input::-webkit-outer-spin-button,
.biller-details .biller-detail input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.biller-details .biller-detail input[type="number"] {
    -moz-appearance: textfield;
}
.biller-details .select {
    background-color: white;
    display: block;
    border: none;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;
    /* max-width: 50rem; */
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    /* padding-right: 2rem !important; */
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    text-indent: 4rem;
}
.biller-details button {
    width: fit-content;
    height: fit-content;
    justify-self: center;
    /* margin-right: 5rem; */
    background: #010080;
    box-shadow: 0px 4px 40px rgba(60, 0, 125, 0.41);
    border-radius: 39.5px;
    border: none;
    color: white;
    padding: 0.7rem 5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
}
.call-icon {
    background-color: red;
    padding: 0.35rem;
    border-radius: 50%;
    position: absolute;
    top: 18%;
    left: 13%;
}
.input-error,
.inputerror {
    border: 1px solid red !important;
    box-shadow: none !important;
}
.error {
    color: red;
    font-size: 1.4rem;
    margin: 0%;
}
@media screen and (max-width: 1100px) {
    .biller-details {
        margin-left: 10rem;
    }
}
@media screen and (min-width: 1380px) {
    .biller-details {
        margin: 5rem 10rem;
        max-width: 100%;
    }
    #quickpay {
        margin: 1rem 10rem !important;
    }
    .biller-detail {
        margin: 0;
    }
    .biller-detail .select,
    .biller-details .biller-detail input {
        width: 70%;
        height: 4.5rem;
    }
    .call-icon,
    .biller-detail .icon {
        left: 16%;
        /* top: 25%; */
        top: 15px;
    }
}
@media screen and (max-width: 767px) {
    .biller-details .biller-detail .select {
        width: 100%;
    }
    .biller-details .biller-detail input {
        width: 100%;
    }
    .biller-details {
        margin: 2rem;
        padding: 3rem;
    }
    .biller-detail {
        margin: 0;
    }
    .call-icon {
        left: 2%;
    }
    .biller-detail .icon {
        left: 2%;
    }
}
/* ----amount */
.amount-wrapper {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
    margin: 0rem 10rem;
    background-color: white;
    padding: 3rem;
    grid-auto-flow: dense;
}
.amount-data label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}
.amount-data {
    display: grid;
    gap: 1rem;
}
.amount-wrapper .amount-data input {
    border: 1px solid #6d6d6b;
    text-indent: 1rem;
    width: 80%;
    height: 3rem;
    border-radius: 7px;
}
.amount-wrapper .amount-data input::placeholder {
    color: #131312;
    /* color: #52525d; */
}
.amount-wrapper .amount-data input[type="text"][disabled] {
    background-color: rgba(128, 128, 128, 0.5);
}
.amount-wrapper .amount-data select {
    background-color: white;
    display: block;
    border: 1px solid grey;

    height: 3rem;
    border-radius: 7px;
    text-indent: 1rem;
    /* max-width: 50rem; */
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    /* padding-right: 2rem !important; */
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    width: 80%;
}
.amount-wrapper .amount-data select option {
    background-color: white !important;
}
.amount-wrapper button {
    /* grid-column: 1/3; */
    grid-column: -1/1;
    align-self: center;
    justify-items: center;
    width: fit-content;
    height: fit-content;
    justify-self: center;
    /* margin-right: 5rem; */
    background: #010080;
    box-shadow: 0px 4px 40px rgba(60, 0, 125, 0.41);
    border-radius: 39.5px;
    border: none;
    color: white;
    padding: 0.7rem 5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
}
@media screen and (max-width: 767px) {
    .amount-wrapper {
        margin: 2rem 0.5rem;
        grid-template-columns: 1fr;
    }
    .amount-wrapper button {
        padding: 1rem 4rem;
    }
}

/* ---quickpay------- */

.quickpay-amount {
    display: grid;
    grid-template-columns: 1fr;
    background-color: white;
    margin: 5rem 25%;
    padding: 4rem 8rem;
    border-radius: 10px;
}
.quickpay-amount .amount-data input,
.quickpay-amount .amount-data select {
    width: 100%;
}
@media screen and (max-width: 720px) {
    .quickpay-amount {
        margin: 1rem;
    }
}
/* ----payment success */

.successWrappe {
    display: grid;
    justify-items: center;
    margin: 3rem 0;
    padding-bottom: 1rem;
}
.successWrapper {
    padding: 1rem 3rem 1rem 2rem;
    background-color: white;
    display: grid;
    gap: 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;
    max-width: 45rem;
}
.successWrapper img {
    align-self: center;
    width: 13%;
    justify-self: end;
}
.successWrapper > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    /* line-height: 22px; */
    /* identical to box height, or 55% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.success-details {
    width: 100%;
    max-width: 30rem;
}
.success-detail P {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #52525d;
}

.success-detail {
    display: grid;
    grid-template-columns: 0.8fr 0.5fr 1fr;
    align-items: center;
}

.success-detail p:nth-child(2) {
    justify-self: center;
}
/* ------complaint status --------- */
.Complaint-status {
    justify-self: center;
    padding: 2rem 5rem;
    display: grid;
    gap: 3rem;
    background-color: white;
    height: fit-content;
    width: fit-content;
    /* min-width: 45rem !important; */
    justify-items: center;
}
.Complaint-status h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 22px;
    /* identical to box height, or 50% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.Complaint-status-item {
    /* justify-items: center; */
    display: grid;
    grid-template-columns: 55% 2% 40%;
    gap: 2rem;
}
.Complaint-status-item h3,
.Complaint-status-item h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-status-item h5 {
    font-weight: 500;
}
.complaint-register {
    display: grid;
    justify-items: center;
}

/* .Complaint-status-items-wrapper {
    display: grid;
} */
.complaint-register {
    height: fit-content;
    padding: 4rem 0;
}
.Complaint-register-item {
    display: grid;
    grid-template-columns: 0.6fr 0.1fr 0.3fr;
    gap: 2rem;
    align-items: center;
}
.Complaint-register-item > h3,
.Complaint-register-item > h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    /* or 92% */
    min-width: 14rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.modal-spinner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content-spinner {
    display: grid;
    justify-items: center;
    gap: 2rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    position: relative;
}
.Complaint-formwrapper .search-transaction {
    margin-left: 5rem !important;
    display: grid;
    grid-template-columns: 0.5fr 0.3fr !important;
    /* flex-direction: row !important; */
}
.Complaint-formwrapper .search-transaction button {
    background: #010080;
    color: white;
    border: none;
    align-self: center;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}
.search-transaction-wrapper {
    display: grid;
    gap: 3rem;
}
.search-transaction-wrapper .or {
    justify-self: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 22px;
    /* identical to box height, or 73% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.search-transaction-form-two {
    margin-left: 5rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    align-items: center;
}
.search-transaction-form-two button {
    background: #010080;
    color: white;
    border: none;
    align-self: center;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}

@media screen and (max-width: 900px) {
    .search-transaction-form-two,
    .search-transaction {
        display: grid;
        grid-template-columns: 1fr !important;
    }
}
/* input[type="date"] {
    display: flex;
    flex-flow: row-reverse;
    padding: 8px;
    height: auto;
    text-indent: 2rem;
} */

.search-transaction-form-two
    .input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 10px;
}
.biller-name {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    /* line-height: 40px; */
    letter-spacing: 0.01em;
    color: #30303c;
}
.payment-btns {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin: 1rem 0 0 0;
}
.payment-methods-heading {
    margin-top: 0.5rem;
    font-weight: bold;
}
.payment-btns div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.payment-btns div p {
    margin: 0 0 0 1rem !important;
    align-self: center;
    /* justify-self: center; */
    font-family: sans-serif;
}
.payment-btns div input {
    pointer-events: unset !important;
    opacity: 1 !important;
    position: unset;
}
.payment-btns div label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    color: black;
    align-self: center;

    font-family: sans-serif;
    font-size: 1.1rem;
    margin-left: 1.4rem;
}
.payment-btns button {
    border: 1px solid #010080;
    background-color: inherit;
}
.payment-btns button:hover,
.payactive {
    color: white;
    background-color: #010080 !important;
}

.load-success {
    display: grid;
    justify-items: center;
    margin-top: 3rem;
}
.load-success div {
    background-color: white;
    width: fit-content;
    display: grid;
    gap: 1.5rem;
    justify-items: center;
    /* margin-top: 2rem; */
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #c8d0d8;
}
.load-success img {
    background-color: inherit;
    width: 200px;
}
.load-success div h3 {
    color: #88b04b;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    /* margin-bottom: 10px; */
}
.load-success div p {
    color: #404f5e;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}
/* --------------refer and earn--------- */
.refertabs {
    display: flex;
    align-items: center;
    margin-left: 7rem;
}
.refertabs button {
    font-family: "poppins";
    padding: 0.5rem 1rem;
    font-weight: 500;
}
.refertabs .tabactive {
    background-color: #010080;
    color: white;
    border: 1px solid #010080;
}
.refertabs .tabinactive {
    border: 1px solid grey;
    background-color: white;
    color: grey;
}
