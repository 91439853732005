.Container {
    background: linear-gradient(to bottom, #e6f1fc 0%, #ffffff 35%);
    position: relative;
    /* background: linear-gradient(to bottom, #e6f1fc, #fff); */
    margin-top: 20rem;
    font-family: "Montserrat", sans-serif;
}
.safe_money {
    background: url("../../../images/HomePage_Updated/safemoney_bg.png");
    height: 250px;
    display: grid;
    grid-template-columns: 26% 1fr;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-items: center;
    width: 70%;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.safe_money img {
    width: 10rem;
}
.safe_money div {
    display: grid;
    align-items: center;
    color: white;
    gap: 1rem;
}
.safe_money div h3 {
    font-size: 30px;
}
.safe_money div p {
    font-size: 1rem;
}
.sub-wrapper {
    padding: 200px 20px;
    padding-bottom: 50px;
    display: grid;
    gap: 2rem;
    align-items: center;
    justify-items: center;
}
.sub-wrapper > h3 {
    text-align: center;
    border-bottom: 4px solid; /* Adjust the border width as needed */
    border-image: linear-gradient(
        90deg,
        #356bbd 0%,
        rgba(211, 225, 247, 0.18) 100%
    );
    border-image-slice: 1;
    padding: 10px; /* Optional: to add some padding inside the border */
    display: inline-block;
    text-align: center;
    letter-spacing: 0.05rem;
    justify-self: center;
    font-size: 30px;
}

.cards {
    width: 50%;
    display: grid;
    gap: 2rem;
    justify-self: center;
}
.card {
    display: grid;
    gap: 1rem;
}
.card header {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.card header h3 {
    color: #0a459b;
    font-size: 24px;
    word-wrap: break-word;
}
.card p {
    /* width: 50%; */
    word-wrap: break-word;
    font-size: 20px;
}
.utilityProcess {
    margin: 1rem 0;
    display: grid;
    justify-items: center;
}
.utilityProcess img {
    width: 80%;
}
@media screen and (max-width: 900px) {
    .utilityProcess {
        width: 100%;
    }
    .utilityProcess img {
        width: 100%;
    }
    .cards {
        width: 80%;
    }
}
.Container button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1rem;
    justify-self: center;
    background-color: #356bbd;
    border-radius: 50px;
    border: none;
    width: fit-content;
    padding: 0.5rem 1rem;
    color: white;
}
@media screen and (max-width: 767px) {
    .sub-wrapper {
        padding: 100px 0rem 3rem 0;
    }

    .safe_money {
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        text-align: center;
        padding: 1rem;
        width: 70%;
        height: fit-content;
    }
    .safe_money img {
        display: none;
    }
    .Container h3 {
        font-size: large !important;
    }
    .Container p {
        font-size: medium !important;
    }
}
