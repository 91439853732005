.Container {
    display: grid;
    align-content: center;
    gap: 0.6rem;
    background: url("../../../images/HomePage_Updated/getstarted.png");
    height: 20rem;
    background-position: top;
    padding-left: 10rem;
    background-size: cover;
    font-family: "Montserrat", sans-serif;

    color: white;
}
.Container h3 {
    border-bottom: 4px solid; /* Adjust the border width as needed */
    border-image: linear-gradient(90deg, white 0%, #356bbd 100%);
    border-image-slice: 1;
    padding: 10px; /* Optional: to add some padding inside the border */
    display: inline-block;
    width: fit-content;
    letter-spacing: 0.05rem;
    font-size: 30px;
}
.Container button {
    width: fit-content;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 20px;
    color: #356bbd;
    font-weight: 600;
}
.Container p {
    font-size: 20px;
    font-weight: 550;
}

@media screen and (max-width: 767px) {
    .Container {
        padding: 0;
        justify-items: center;
    }
    .Container h3 {
        font-size: large;
    }
    .Container p {
        font-size: medium;
    }
}
